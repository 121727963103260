body {
  margin: 0 !important;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

div {
  box-sizing: border-box;
}

/* Left in place to avoid btn resize */
.css-1mzzuk6 {
  box-sizing: content-box !important;
}

html {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 0.5vw;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px #214f61;
  border-radius: 0px;
}

/* Handle #43a1a2 or #214F61 or #ADADAD */
body::-webkit-scrollbar-thumb {
  box-shadow: inset 4px 2px 12px 2px #214f61;
  border-radius: 4px;
}


.wrs_selected{
  height: auto !important;
}


.wrs_modal_dialogContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;

}
