
* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  /* font-family: 'Coustard'; */
}

.App {
    padding-top: 5rem;
    width: 100%;
    height: 100vh;
}

.ReactModal__Overlay  {
  z-index: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

